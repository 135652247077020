// Here you can add other styles
.example {
    // Existing styles...
  
    table {
      background-color: red; // Makes the table background red
      color: red; // Optional: Makes the text inside the table white for better contrast
  
      th, td {
        border-color: darkred; // Optional: Change the table border color to dark red
      }
    }
  }
  